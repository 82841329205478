<template>
    <div class="page-warp individual-creat-page">
        <van-form v-model="submitForm" ref="submitForm">
            <div class="row page-model-title">基本信息</div>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="submitForm.industryName"
                label="行业类型"
                placeholder="请选择行业类型"
                @click="showHY = true"
                />
            <div class="van-cell van-cell--clickable van-field">
                <div class="van-cell__title van-field__label">
                    <span>经营范围</span>
                </div>
                <div class="van-cell__value van-field__value">
                    <div class="van-field__body">{{submitForm.businessScope}}</div>
                </div>
            </div>
            <van-field
                readonly
                v-model="submitForm.areaName"
                label="选择园区"
                placeholder="点击选择园区"
                />
            <van-field
                v-model="submitForm.registrationServiceFeeD"
                readonly
                label="注册服务费"
                placeholder="请输入注册服务费"/>
            <van-field
                v-model="submitForm.customerServiceFeeRateStr"
                readonly
                label-width="2.8rem"
                label="平台管理收费率"
                placeholder="请输入平台管理收费率"/>
            <van-field
                readonly
                v-model="zzText"
                label="组织形式"
                placeholder="请选择组织形式"
                />
            <van-field
                readonly
                v-model="zhText"
                label="字号"
                placeholder="请选择字号"
                />
            <van-field
                readonly
                v-if="submitForm.nameType == 2"
                v-model="submitForm.mainName"
                label="主选（取名）"
                placeholder="输入2-4字，非重复字，不可用英文数字"/>
            <van-field
                readonly
                v-if="submitForm.nameType == 2"
                v-model="submitForm.alternativeName"
                label="备选（取名）"
                placeholder="输入2-4字，非重复字，不可用英文数字"/>
            <van-field
                readonly
                v-model="submitForm.linkerMobile"
                label="联系电话"
                placeholder="请输入联系电话"/>
            <van-field
                v-model="submitForm.idCardName"
                label="身份证姓名"
                placeholder="请输入身份证姓名"/>
            <van-field
                readonly
                v-model="submitForm.idCardNo"
                label="身份证号码"
                placeholder="请输入身份证号码"/>
            <!-- <van-field
                v-model="submitForm.idCardExpiryDate"
                label="身份证到期日期"
                readonly
                @click="dataOpen=true"
                placeholder="请输入身份证到期日期"/> -->
            <van-field
                readonly
                clickable
                name="calendar"
                :value="submitForm.idCardExpiryDate"
                label="身份证有效期"
                placeholder="请输入身份证到期日期"
                @click="dataOpen = true"
                />
            <van-cell title="法人身份证  正/背面" label="描述信息">
                <template #label>
                    <span style="color:#278BF9;">正面为头像面    背面为国徽面</span>
                </template>
            </van-cell>
            <div class="row upload-idcar" v-if="submitForm.idCardPhotoFront">
                <div class="row u-id-main">
                    <div class="u-id-text">
                        <span>头像面</span>
                    </div>
                    <div class="u-id-img">
                        <img :src="submitForm.idCardPhotoFront" />
                    </div>
                </div>
            </div>
            <div class="row upload-idcar mt-10" v-if="submitForm.idCardPhotoReverse">
                <div class="row u-id-main">
                    <div class="u-id-text">
                        <span>国徽面</span>
                    </div>
                    <div class="u-id-img">
                        <img :src="submitForm.idCardPhotoReverse" />
                    </div>
                </div>
            </div>
        </van-form>
    </div>
</template>
<script>
import { selAreaList,industryTypes,organizationType,nameType,individualCreate } from '@/api/individual'
import { upDateFile } from "@/api/upFile";
export default {
    name:'invoiceAdd',
    data() {
        return {
            activeNames: [],
            loading: false,
            finished: false,
            dataOpen:false,
            minDate: new Date(1900, 0, 1),
            submitForm:{
                industryId:'',
                industryName:'',
                businessScope:'',
                areaId:'',
                areaName:'',
                organizationType:'',
                organizationTypeName:'',
                nameType:'',
                nameTypeName:'',
                mainName:'',
                alternativeName:'',
                idCardPhotoFront:[],
                idCardPhotoReverse:[],
                idCardName:'',
                idCardNo:'',
                idCardExpiryDate:'',
                linkerMobile:'',
                registrationServiceFee:'',
            },
            // 行业
            showHY:false,
            industryTypesLoop:[],
            industryTypesList:[],
            // 园区
            showYQ:false,
            areaLoop:[],
            areaList:[],

            // 组织形式
            showZZ:false,
            organizationTypeList:[],
            organizationTypeLoop:[],
            // 组织形式
            showZH:false,
            nameTypeList:[],
            nameTypeLoop:[],
            zhText:'',
            zzText:'',
        };
    },
    created(){
        this.submitForm = sessionStorage.getItem('individualDetail') ? JSON.parse(sessionStorage.getItem('individualDetail')) : {}
        this.$nextTick(()=>{
            this.getOrganizationType();
            this.getNameType();
        })
    },
    methods: {
        // 组织形式
        getOrganizationType(){
            organizationType().then((res)=>{
                res.data.map(item=>{
                    if(item.dictValue == this.submitForm.organizationType){
                        this.zzText = item.dictLabel;
                        this.submitForm.organizationTypeName = item.dictLabel;
                    }
                })
            })
        },
        // 字号 
        getNameType(){
            nameType().then((res)=>{
                res.data.map(item=>{
                    if(item.dictValue == this.submitForm.nameType){
                        this.zhText = item.dictLabel;
                        this.submitForm.nameTypeName = item.dictLabel;
                    }
                })
            })
        },
        // 获取行业类型
        getiIndustryTypes(){
            industryTypes().then((res)=>{
                this.industryTypesList = res.data;
                this.industryTypesLoop = [];
                res.data.map(item=>{
                    this.industryTypesLoop.push(item.industryName)
                })
            })
        },
        // 获取园区列表
        getSelAreaList(){
            var as1 = [
                {areaName:'test',id:2,customerServiceFee:786.98}
            ]
            this.areaList = as1;
            this.areaLoop = [];
            as1.map(item=>{
                this.areaLoop.push(item.areaName)
            })
            return true
            selAreaList({industryId:this.submitForm.industryId}).then((res)=>{
                this.areaList = res.data;
                this.areaLoop = [];
                res.data.map(item=>{
                    this.areaLoop.push(item.areaName)
                })
            })
        },
        // 选择行业
        onConfirmHY(value,index) {
            this.submitForm.industryId = this.industryTypesList[index].id;
            this.submitForm.industryName = value;
            this.submitForm.businessScope = this.industryTypesList[index].businessScope;
            this.showHY = false;
            this.getSelAreaList();
        },
        // 选择园区
        onConfirmYQ(value,index) {
            this.submitForm.areaName = value;
            this.submitForm.areaId = this.areaList[index].id;
            this.submitForm.registrationServiceFee = this.areaList[index].customerServiceFee
            this.showYQ = false;
        },
        // 选择组织
        onConfirmZZ(value,index) {
            this.submitForm.organizationTypeName = value;
            this.submitForm.organizationType = this.organizationTypeList[index].dictValue;
            this.showZZ = false;
        },
        onConfirmZH(value,index) {
            this.submitForm.nameTypeName = value;
            this.submitForm.nameType = this.nameTypeList[index].dictValue;
            this.submitForm.mainName = '';
            this.submitForm.alternativeName = '';
            this.showZH = false;
        },
        onConfirmDate(date){
            this.submitForm.idCardExpiryDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            this.dataOpen = false;
        },
        submitSave(){
            if(!this.submitForm.industryId){
                this.$toast.fail('行业类型不能为空');
                return false;
            }
            if(!this.submitForm.areaId){
                this.$toast.fail('园区不能为空');
                return false;
            }
            if(!this.submitForm.organizationType){
                this.$toast.fail('组织形式不能为空');
                return false;
            }
            if(!this.submitForm.nameType){
                this.$toast.fail('字号不能为空');
                return false;
            }
            if(!this.submitForm.mainName){
                this.$toast.fail('主选不能为空');
                return false;
            }
            if(!this.submitForm.alternativeName){
                this.$toast.fail('备选不能为空');
                return false;
            }
            if(!this.submitForm.idCardPhotoFront){
                this.$toast.fail('身份证正面图片不能为空');
                return false;
            }
            if(!this.submitForm.idCardPhotoReverse){
                this.$toast.fail('身份证反面图片不能为空');
                return false;
            }
            if(!this.submitForm.idCardName){
                this.$toast.fail('身份证姓名不能为空');
                return false;
            }
            if(!this.submitForm.idCardNo){
                this.$toast.fail('身份证号码不能为空');
                return false;
            }
            if(!this.submitForm.idCardExpiryDate){
                this.$toast.fail('身份证到期日期不能为空');
                return false;
            }
            if(!this.submitForm.linkerMobile){
                this.$toast.fail('联系电话不能为空');
                return false;
            }
            let where = JSON.parse(JSON.stringify(this.submitForm));
            where.idCardPhotoFront = where.idCardPhotoFront[0].file && where.idCardPhotoFront[0].file.url?where.idCardPhotoFront[0].file.url : '';
            where.idCardPhotoReverse = where.idCardPhotoReverse[0].file&&where.idCardPhotoReverse[0].file.url ? where.idCardPhotoReverse[0].file.url : '';
            individualCreate(where).then(response => {
                this.$toast.success(response.msg);
            });
        }
    },
};
</script>
<style lang="less" scoped>
.individual-creat-page {
    padding-bottom:40px;
}
.upload-idcar {
    box-sizing: border-box;
    padding:0px 10px;
    position: relative;
    .u-id-main { 
        position: relative; background:#F5F5F5;
        box-sizing: border-box;
        padding:10px 0px;
    }
    .u-id-text {
        width:40%;float:left;
        position: absolute;
        left:10px; top:50%; transform: translateY(-50%);
        span {
            display: block;
            width: 100%; height: auto; overflow: hidden;
            text-align: left;
            &:first-child {
                font-size:16px;
                color:#080808;
            }
            &:last-child {
                font-size:12px;
                color:rgba(0, 0, 0, 0.6)
            }
        }
    }
    .u-id-img {
        width: 50%; float: right; margin-right:10px;
        background-position: center center;
        background-size: auto 100%;
        background-repeat: no-repeat; text-align: center;
        img {
             max-height:120px;  margin: 0 auto;
        }
        &.z {
            background-image: url('../../assets/images/sfzzm@2x.png'); 
        }
        &.f {
            background-image: url('../../assets/images/sfzfm@2x.png'); 
        }
    }
}
.page-model-title {
    text-align: left; line-height: 40px; font-size: 14px; color: #080808;
    box-sizing: border-box; padding:0px 10px;
    span {
        color: #278BF9; float: right;
    }
}
</style>
<style lang="less">
.individual-creat-page {
    .upload-idcar {
        .u-id-img {
            .van-uploader { width:100%;}
            .van-uploader__upload { width: 100%; margin:0px;}
            .van-uploader__wrapper { display: block;}
            &.z,
            &.f{
                .van-uploader { opacity: 0;}
            }
        }
    }
}
</style>